@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.bg-fixed-background {
    background-image: url('./Assets/grad-students-throwing-hats-in-the-air.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    min-height: 100vh;
  }

  html {
    scroll-behavior: smooth;
    scroll-padding-top: 8px; /* Adjust as necessary */
  }